<template>
  <div
    :class="`header-right-btn ` + (autoWidth ? `auto-width` : '')"
    @click="$emit('click')"
  >
    {{ text }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'HeaderRightBtn',
  props: {
    text: {
      type: String,
      default: () => ''
    },
    autoWidth: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },

}
</script>

<style scoped lang="scss">
.header-right-btn{
  opacity: 0.8;
  &:hover{
    opacity: 1;
  }
}
</style>
