<template>
  <div>
    <footer-pc
      v-if="browser===1"
      :class="path"
    />
    <footer-mobile v-if="browser===2" />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import FooterPc from "@/components/Pc/common/Footer";
import FooterMobile from "@/components/Mobile/common/Footer";
export default {
  name: 'Footer',
  components: {FooterMobile, FooterPc},
  computed:  {
    ...mapState(['browser', 'menu']),
    path(){
      return this.$route.path.slice(1)
    }
  },
}
</script>

<style scoped lang="scss"></style>
