<template>
  <div
    class="user-logined-pc"
    @mouseover="show=true"
  >
    <div class="username">
      {{ user.name }}
    </div>
    <div
      v-show="show"
      class="user-logined-modal"
      @mouseleave="show=false"
    >
      <div class="sanjiao"></div>
      <ul>
        <template v-for="(res, index) in userMenu">
          <li
            :key="index"
            :class="nowPath===res.link ? `on` : ``"
            @click="navigateTo(res.link)"
          >
            {{ $t(res.title) }}
          </li>
        </template>
        <li
          class="border-top"
          @click="logout"
        >
          {{ $t('30005') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'UserLoginedPc',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      show: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user', 'userMenu']),
    nowPath: function(){
      return '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
    }
  },
  methods:{
    logout(){
      this.$confirm('是否要退出当前账号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'pc-message'
      }).then(() => {
        this.$store.commit('setUser', {})
        this.navigateTo('/exhibition')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-logined-pc{
  display: inline-block;
  margin-left: 12px;
  color: #EA4C1E;
  position: relative;
  cursor: pointer;
  .username{
    max-width: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-logined-modal{
    position: absolute;
    left: -120px;
    top: 30px;
    background: #333;
    min-width: 148px;
    box-shadow: 0 2px 6px 0 rgba(0, 26, 51, 0.1);
    padding-top: 5px;
    z-index: 2;
    ul{
      li{
        white-space: nowrap;
        padding: 0 22px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        &.on, &:hover{
          background: linear-gradient(223deg, #E94D1D 0%, #FFAB91 100%);
          color: #fff;
        }
        &.border-top{
          border-top: 1px solid rgba(255,255,255,0.1);
        }
      }
    }
    .sanjiao{
      position: absolute;
      top: -6px;
      right: 10px;
      width: 0;
      height: 0;
      border-bottom: 6px solid #333;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }
}
</style>
