<template>
  <div v-show="show">
    <div class="zhanwei"></div>
    <div
      class="footer-mobile"
      :class="footer2Show ? `footer2` : ``"
    >
      <div
        v-show="!footer2Show"
        class="jiantou"
      ></div>
      <template v-for="(res, index) in footerMenu">
        <footer-item-mobile
          :key="index"
          :info="res"
          :on="footerActive1(res.modelname)"
          @click="res.click"
        />
      </template>
    </div>
    <div
      class="footer-mobile-2"
      :style="{zIndex: zIndex}"
      @click="hideMenu2"
    >
      <div class="footer-mobile-2-bg"></div>
      <div
        class="menu2"
        :class="footer2Show ? `on` : ``"
      >
        <div
          class="list"
        >
          <div
            class="line"
            @click="hideMenu2"
          ></div>
          <template v-for="(res, index) in footerMenu2">
            <footer-item2-mobile
              :key="index"
              :info="res"
              :on="footerActive1(res.modelname)"
              @click="res.click"
            />
          </template>
        </div>
        <div class="middle-view">
          <div class="pic">
            <img src="../../../../assets/images/mobile/middle_on.png" />
          </div>
          <div class="text">
            更多
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import FooterItemMobile from "@/components/Mobile/common/Footer/components/item";
import FooterItem2Mobile from "@/components/Mobile/common/Footer/components/item2";

export default {
  name: 'FooterMobile',
  components: {FooterItem2Mobile, FooterItemMobile},
  data(){
    return {
      footer2Show: false,
      zIndex: -2,
      footerMenu: [
        {
          title: '展览',
          langid: 10002,
          modelname: 'exhibition',
          icon: require('../../../../assets/images/mobile/exhibition.png'),
          icon_on: require('../../../../assets/images/mobile/exhibition_on.png'),
          click: () => {this.hideMenu2();this.navigateTo('/exhibition')}
        },
        {
          title: '论坛',
          modelname: 'forum',
          langid: 10003,
          icon: require('../../../../assets/images/mobile/bbs.png'),
          icon_on: require('../../../../assets/images/mobile/bbs_on.png'),
          click: () => {this.hideMenu2();this.navigateTo('/forum')}
        },
        {
          title: '更多',
          type: 'middle',
          langid: 50017,
          modelname: 'middle',
          icon: require('../../../../assets/images/mobile/middle.png'),
          icon_on: require('../../../../assets/images/mobile/middle_on.png'),
          click: () => {
            if(this.footer2Show){
              this.hideMenu2()
            }else{
              this.zIndex = 100000
              this.footer2Show = true
            }
          }
        },
        {
          title: '直播',
          modelname: 'live',
          langid: 10004,
          icon: require('../../../../assets/images/mobile/live.png'),
          icon_on: require('../../../../assets/images/mobile/live_on.png'),
          click: () => {this.hideMenu2();this.navigateTo('/live')}
        },
        {
          title: '我的',
          modelname: 'user',
          langid: '10024',
          icon: require('../../../../assets/images/mobile/user.png'),
          icon_on: require('../../../../assets/images/mobile/user_on.png'),
          click: () => {this.hideMenu2();this.navigateTo('/user')}
        }
      ],
      footerMenu2: [
        {
          title: '评奖',
          langid: 10005,
          modelname: 'awards',
          icon: require('../../../../assets/images/mobile/awards.png'),
          icon_on: require('../../../../assets/images/mobile/awards_on.png'),
          click: () => {this.hideMenu2(); this.navigateTo('/awards')}
        },
        {
          title: '资讯',
          modelname: 'news',
          langid: 10006,
          icon: require('../../../../assets/images/mobile/news.png'),
          icon_on: require('../../../../assets/images/mobile/news_on.png'),
          click: () => {this.hideMenu2(); this.navigateTo('/news')},
          enHide: true
        },
        {
          title: '活动',
          modelname: 'activity',
          langid: 10007,
          icon: require('../../../../assets/images/mobile/activity.png'),
          icon_on: require('../../../../assets/images/mobile/activity_on.png'),
          click: () => {this.hideMenu2(); this.navigateTo('/activity')},
          enHide: true
        },
        {
          title: '案例',
          modelname: 'case',
          langid: 10008,
          icon: require('../../../../assets/images/mobile/case.png'),
          icon_on: require('../../../../assets/images/mobile/case_on.png'),
          click: () => {this.hideMenu2(); this.navigateTo('/case')},
          enHide: true
        },
        {
          title: '专题',
          modelname: 'topic',
          langid: 10009,
          icon: require('../../../../assets/images/mobile/topic.png'),
          icon_on: require('../../../../assets/images/mobile/topic_on.png'),
          click: () => {
              this.hideMenu2();
              this.navigateTo('/topic_cover')
          },
          enHide: true
        },
      ]
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'noFooter']),
    show(){
      return this.noFooter.indexOf(this.$route.path) === -1;
    }
  },
  methods: {
    footerActive1(v) {
      const module = this.$route.path.split('/')[1]
      if(v === 'middle'){
        let middleModules = ['awards', 'news', 'activity', 'case', 'topic']
        if(middleModules.indexOf(module.toLowerCase()) > -1){
          return true
        }
        return false
      }
      return module.toLowerCase() === v.toLowerCase();
    },
    hideMenu2(){
      this.footer2Show = false;
      this.zIndex = -2
    }
  }
}
</script>

<style scoped lang="scss">
.zhanwei{
  height: 148px;
}
.footer-mobile {
  height: 148px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: url("../../../../assets/images/mobile/bg_botoom.png");
  background-position: center center;
  background-size: cover;
  .jiantou{
    display: none;
    transition: all 0.6s ease-in-out;
    width: 68px;
    height: 68px;
    position: absolute;
    left: 50%;
    margin-left: -34px;
    background: url("../../../../assets/images/mobile/up.png");
    background-size: 100% 100%;
    bottom: 178px;
  }
}
.footer-mobile-2{
  position: fixed;
  width: 100%;
  z-index: 100000;
  top: 0;
  bottom: 100px;
  .footer-mobile-2-bg{
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 48px;
  }
  .menu2{
    background: url("../../../../assets/images/mobile/menu_bg.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    position: absolute;
    bottom: -500px;
    left: 14px;
    right: 14px;
    height: 316px;
    .list{
      //transition: all 0.6s ease-in-out;
      border-radius: 12px;
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .line{
        width: 48px;
        height: 6px;
        //background: #9DA6B6;
        background: rgba(0,0,0,0);
        border-radius: 3px;
        position: absolute;
        left: 50%;
        margin-left: -24px;
        top: 16px;
      }
    }
    .middle-view {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 23px;
      .pic{
        width: 70px;
        height: 70px;
      }
      .text{
        font-size: 20px;
        font-weight: 400;
        color: #FF754D;
        line-height: 28px;
        margin-top: 4px;
        overflow: hidden;
      }
    }
    &.on{
      bottom: -76px;
      z-index: 100000;
    }
  }
}
</style>
