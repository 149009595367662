<template>
  <div
    class="video-player-pc"
  >
    <div
      class="bg"
    >
      <div
        class="close-btn"
        @click="hidePlayer"
      ></div>
    </div>
    <div class="player" style="color: #fff">
      <video
          :src="videoUrl"
          x5-playsinline
          webview.allowsInlineMediaPlayback=YES
          webkit-playsinline
          playsinline
          autoplay
          controls
          @click="stop"
          id="videosplayer"
      ></video>
<!--      <vue-aliplayer-v2-->
<!--        ref="VueAliplayerV2"-->
<!--        autoplay-->
<!--        :options="options"-->
<!--        :source="videoUrl"-->
<!--      />-->
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import VueAliplayerV2 from "vue-aliplayer-v2";

export default {
  name: 'VideoPlayerPc',
  components: {
    VueAliplayerV2: VueAliplayerV2.Player
  },
  props: {
    hidePlayer: {
      type: Function,
      default: () => () => {}
    },
    videoUrl: {
      type: String,
      default: () => ''
    }
  },
  data(){
    return {
      options: {
        height: '100%',
        isLive: true,
        autoplay: true,
        diagnosisButtonVisible: false
      }
    }
  },
  methods:{
    play(){
      document.getElementById('videosplayer').play();
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.video-player-pc{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  .close-btn{
    width: 42px;
    height: 42px;
    background: url("../../../../assets/images/player_close.png");
    background-size: 100%;
    background-position: center;
    position: absolute;
    right: 27px;
    top: 19px;
  }
  .bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
  }
  .player{
    width: 608px;
    height: 100%;
    position: absolute;
    left: 50%;
    margin-left: -304px;
    video{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
