<template>
  <div
    class="fixed-menu-item-pc"
    @click="$emit('click')"
  >
    <div class="icon">
      <img :src="require('../../../../../assets/images/'+icon)" />
    </div>
    <div class="text">
      {{ $t(text) }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'FixedMenuItemPc',
  props: {
    text: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.fixed-menu-item-pc{
  height: 92px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  .icon{
    margin-top: 16px;
    width: 40px;
    height: 40px;
    display: inline-block;
    img{
      width: 40px;
      height: 40px;
    }
  }
  .text{
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 16px;
  }
  &:hover{
    background: #fff;
  }
}
</style>
