// 如果没有通过拦截器配置域名的话，可以在这里写上完整的URL(加上域名部分)
import common from '@/api/modules/common'
import news from '@/api/modules/news'
import user from '@/api/modules/user'
import search from '@/api/modules/search'
import supplydemand from '@/api/modules/supplydemand'
import bbs from '@/api/modules/bbs'
import live from '@/api/modules/live'
import exhibition from '@/api/modules/exhibition'
import cases from '@/api/modules/case'
import topic from '@/api/modules/topic'
import activity from '@/api/modules/activity'
import awards from '@/api/modules/awards'
import ajax from '@/api/modules/ajax'
const install = (Vue) => {
	let users = {
		sendSms: (params = {}) => Vue.prototype.$u.post(user.sendSms.api, params),
		actCheck: (params = {}) => Vue.prototype.$u.post(user.actCheck.api, params),
		actCheckToken: (params = {}) => Vue.prototype.$u.post(user.actCheckToken.api, params),
		getMyCang: (params = {}) => Vue.prototype.$u.post(user.getMyCang.api, params),
		getMySchedule: (params = {}) => Vue.prototype.$u.post(user.getMySchedule.api, params),
		getSupplyLists: (params = {}) => Vue.prototype.$u.post(user.getSupplyLists.api, params),
		getMyMingpian: (params = {}) => Vue.prototype.$u.post(user.getMyMingpian.api, params),
		getMyFollow: (params = {}) => Vue.prototype.$u.post(user.getMyFollow.api, params),
		actSaveSupply: (params = {}) => Vue.prototype.$u.post(user.actSaveSupply.api, params),
		actSupplyDelete: (params = {}) => Vue.prototype.$u.post(user.actSupplyDelete.api, params),
		getSupplyOne: (params = {}) => Vue.prototype.$u.post(user.getSupplyOne.api, params),
		dropMySchedule: (params = {}) => Vue.prototype.$u.post(user.dropMySchedule.api, params),
		getSupplyProductLists: (params = {}) => Vue.prototype.$u.post(user.getSupplyProductLists.api, params),
		getUserInfo: (params = {}) => Vue.prototype.$u.post(user.getUserInfo.api, params),
		checkBind: (params = {}) => Vue.prototype.$u.post(user.checkBind.api, params),
		getUserInfoByCode: (params = {}) => Vue.prototype.$u.get(user.getUserInfoByCode.api+'?code='+params.code+'&mid='+params.mid, params),
		sub: (params = {}) => Vue.prototype.$u.post(user.sub.api, params),
		checkSub: (params = {}) => Vue.prototype.$u.post(user.checkSub.api, params),
	}
	let ajaxs = {
		actZan: (params = {}) => Vue.prototype.$u.post(ajax.actZan.api, params),
		actShare: (params = {}) => Vue.prototype.$u.post(ajax.actShare.api, params),
		actCang: (params = {}) => Vue.prototype.$u.post(ajax.actCang.api, params),
		actCard: (params = {}) => Vue.prototype.$u.post(ajax.actCard.api, params),
		actCNZZ: (params = {}) => Vue.prototype.$u.post(ajax.actCNZZ.api, params),
		actCNZZAds: (params = {}) => Vue.prototype.$u.post(ajax.actCNZZAds.api, params),
		actComment: (params = {}) => Vue.prototype.$u.post(ajax.actComment.api, params),
		actLive: (params = {}) => Vue.prototype.$u.post(ajax.actLive.api, params),
	}
	let topics = {
		getLists: (params = {}) => Vue.prototype.$u.post(topic.getLists.api, params),
		getCategory: (params = {}) => Vue.prototype.$u.post(topic.getCategory.api, params),
	}
	let searchs = {
		getHistoryKeyword: (params = {}) => Vue.prototype.$u.post(search.getHistoryKeyword.api, params),
		getSearchLists: (params = {}) => Vue.prototype.$u.post(search.getSearchLists.api, params),
		getSearchResultInfo: (params = {}) => Vue.prototype.$u.post(search.getSearchResultInfo.api, params),
		checkSearchKeyword: (params = {}) => Vue.prototype.$u.post(search.checkSearchKeyword.api, params),
	}
	let supplydemands = {
		getLists: (params = {}) => Vue.prototype.$u.post(supplydemand.getLists.api, params),
		getDetail: (params = {}) => Vue.prototype.$u.post(supplydemand.getDetail.api, params),
		actfollow: (params = {}) => Vue.prototype.$u.post(supplydemand.actfollow.api, params),
	}
	let awardss = {
		getVideo: (params = {}) => Vue.prototype.$u.post(awards.getVideo.api, params),
		getLists: (params = {}) => Vue.prototype.$u.post(awards.getLists.api, params),
		getDetail: (params = {}) => Vue.prototype.$u.post(awards.getDetail.api, params),
	}
	let lives = {
		getDetail: (params = {}) => Vue.prototype.$u.post(live.getDetail.api, params),
		getLists: (params = {}) => Vue.prototype.$u.post(live.getLists.api, params),
		getVideoLists: (params = {}) => Vue.prototype.$u.post(live.getVideoLists.api, params),
		checkLoading: (params = {}) => Vue.prototype.$u.post(live.checkLoading.api, params),
		getHistoryLists: (params = {}) => Vue.prototype.$u.post(live.getHistoryLists.api, params),
		getCommentLists: (params = {}) => Vue.prototype.$u.post(live.getCommentLists.api, params),
	}
	let exhibitions = {
		getParentTypes: (params = {}) => Vue.prototype.$u.post(exhibition.getParentTypes.api, params),
		getChildTypes: (params = {}) => Vue.prototype.$u.post(exhibition.getChildTypes.api, params),
		getLists: (params = {}) => Vue.prototype.$u.post(exhibition.getLists.api, params),
		getDetail: (params = {}) => Vue.prototype.$u.post(exhibition.getDetail.api, params),
		getProductDetail: (params = {}) => Vue.prototype.$u.post(exhibition.getProductDetail.api, params),
		getProductLists: (params = {}) => Vue.prototype.$u.post(exhibition.getProductLists.api, params),
	}
	let bbss = {
		getLists: (params = {}) => Vue.prototype.$u.post(bbs.getLists.api, params),
		getDetail: (params = {}) => Vue.prototype.$u.post(bbs.getDetail.api, params),
		getJiabinDetail: (params = {}) => Vue.prototype.$u.post(bbs.getJiabinDetail.api, params),
	}
	let newss = {
		/**
		 * 获取资讯推荐列表
		 * @param params
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		getRecom: (params = {}) => Vue.prototype.$u.post(news.getRecom.api, params),
		getLists: (params = {}) => Vue.prototype.$u.post(news.getLists.api, params),
		getDetail: (params = {}) => Vue.prototype.$u.post(news.getDetail.api, params),
		getSearchResultInfo: (params = {}) => Vue.prototype.$u.post(news.getSearchResultInfo.api, params),
	}
	let casess = {
		/**
		 * 获取资讯推荐列表
		 * @param params
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		getLists: (params = {}) => Vue.prototype.$u.post(cases.getLists.api, params),
		getDetail: (params = {}) => Vue.prototype.$u.post(cases.getDetail.api, params),
	}
	let activitys = {
		/**
		 * 获取资讯推荐列表
		 * @param params
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		getLists: (params = {}) => Vue.prototype.$u.post(activity.getLists.api, params),
		getDetail: (params = {}) => Vue.prototype.$u.post(activity.getDetail.api, params),
		actSaveMessage: (params = {}) => Vue.prototype.$u.post(activity.actSaveMessage.api, params),
	}
	let commons = {
		/**
		 * 获取子分类
		 * @param params
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		getChildArchivesType: (params = {}) => Vue.prototype.$u.post(common.getChildArchivesType.api, params),
		/**
		 * 获取数据字典
		 * @param params
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		getDictionaryConfig: (params = {}) => Vue.prototype.$u.post(common.getDictionaryConfig.api, params),
		/**
		 * 获取关于我们字段
		 * @param params
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		getAboutDetail: (params = {}) => Vue.prototype.$u.post(common.getAboutDetail.api, params),
		/**
		 * 获取图片列表
		 * @param params
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		getNewAds: (params = {}) => Vue.prototype.$u.post(common.getNewAds.api, params),

		getLiveDate: (params = {}) => Vue.prototype.$u.post(common.getLiveDate.api, params),
		getRanking: (params = {}) => Vue.prototype.$u.post(common.getRanking.api, params),
		getSiteConfig: (params = {}) => Vue.prototype.$u.post(common.getSiteConfig.api, params),
		getWxConfig: (params = {}) => Vue.prototype.$u.post(common.getWxConfig.api, params),
		getZhanAds: (params = {}) => Vue.prototype.$u.post(common.getZhanAds.api, params),
	}
	Vue.prototype.$u.api = { ajaxs, awardss, users, commons, newss, casess, activitys, topics, exhibitions, bbss, lives, supplydemands, searchs }
}

export default { install }
