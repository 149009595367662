import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../../store/index.js'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n)
export const i18n = new VueI18n({
	locale: '',
	silentTranslationWarn: true
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  return lang
}
Vue.prototype._i18n = i18n

export default function loadLanguageAsync(lang) {
 // 如果语言相同
 if (i18n.locale === lang) {
	store.commit('setLang', lang)
    return Promise.resolve(setI18nLanguage(lang))
 }

 // 如果语言已经加载
 if (loadedLanguages.includes(lang)) {
	store.commit('setLang', lang)
    return Promise.resolve(setI18nLanguage(lang))
 }
 store.commit('setLang', lang)
 return new Promise((resolve, reject) => {
	 setTimeout(function(){
		 Vue.prototype.$u.api.commons.getDictionaryConfig().then(res => {
			 Vue.prototype.loadingInstance.close()
			 if(res.status === 1){
				 store.commit('setLang', lang)
				 let language = {
				 	zh: {
				 		lang: 'EN',
						loading: '加载中...',
						全部: '全部',
						'el.pagination.goto': '跳转',
						'el.pagination.pageClassifier': '页',
						'上一页': '上一页',
						'下一页': '下一页'
					},
					en: {
				 		lang: '中',
						loading: 'Loading...',
						全部: 'All',
						'el.pagination.goto': 'jump',
						'el.pagination.pageClassifier': 'page',
						'上一页': 'previous page',
						'下一页': 'next page'
				 	}
				 }
				 let path = {}
				 for(let key in res.data){
				 	let item = res.data[key]
					 language.zh[key] = item.name
					 language.en[key] = item.ename
					 if(item.path){
						 path[key] = item.path
					 }
				 }
				 store.commit('setLangLoaded')
				 store.commit('setMenuPath', path)
				 i18n.setLocaleMessage(lang, language[lang])
				 loadedLanguages.push(lang)
				 setI18nLanguage(lang)
				 resolve(res.data)
			 }
		 })
	 })
 })
}
