<template>
  <div
    id="app"
    :class="browser === 1 ? ('pc ' + lang + ' ' + (bili >= 0.65 ? `pingban` : ``) + bodyclassname) : ('mobile ' + lang+ ' ' + (bili >= 0.65 ? `pingban` : ``) + bodyclassname)"
  >
    <div :class="classname">
      <div
        v-show="share"
        class="mengban"
        @click="closeMengban"
      ></div>
      <Header v-if="langLoaded" />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <Footer v-if="langLoaded" />
      <FixedMenuPc v-if="browser===1" />
      <video-player-pc
        v-if="playerShow"
        :hide-player="hidePlayer"
        :video-url="videoUrl"
        ref="player"
        load
      />
      <div
        v-if="hengping"
        class="hengping"
      >
        <div class="hengping-pic" />
        <div>为了更好的体验，请使用竖屏浏览</div>
      </div>
    </div>
    <subscribe-modal-mobile
        template="0TmvQEKhXVy3vNX3T6oWGZPMPM1awQEljfyfUvnqpwE,Wt3i9oe-FKfhbuJBlvsDAhP5CCzbaKWHJX87VV3PZTg"
        type="forum"
        type-name="论坛"
        ref="sub"
        v-if="wxa"
    />
  </div>
</template>

<script>

import Header from "@/components/Default/Common/header";
import {mapState} from "vuex";
import store from '@/store'
import Footer from "@/components/Default/Common/footer";
import FixedMenuPc from "@/components/Pc/common/FixedMenu";
import VideoPlayerPc from "./components/Pc/common/VideoPlayer";
import SubscribeModalMobile from "@/components/Mobile/common/SubscribeModal/index.vue";
import wx from 'weixin-js-sdk';
import Vue from "vue";
export default {
  name: 'App',
  components: {SubscribeModalMobile, VideoPlayerPc, FixedMenuPc, Footer, Header},
  data(){
    return {
      playerShow: false,
      videoUrl: '',
      hengping: false,
      classname: '',
      bili: 1,
      wxa: false,
    }
  },
  computed:  {
    ...mapState(['browser','langLoaded', 'share', 'lang', 'bodyclassname', 'user'])
  },
  beforeMount() {
    this.init();
    this.getUserInfo();
    var ww=document.body.clientWidth;
    var wh=document.body.clientHeight;
    this.bili = ww/wh
    localStorage.removeItem('checkToken')
  },
  mounted() {
    this.checkWeChatCode();
    this.$bus.$on('showPlayer', (videoUrl) => {
      this.videoUrl = videoUrl
      this.playerShow = true
      this.$nextTick(() => {
        this.$refs.player.play();
      })
    })
    this.$bus.$on('showSub', ({id, typeName, template, type}) => {
      this.$refs.sub.show(id, typeName, template, type);
    })
    let url = window.location.href
    Vue.prototype.$u.api.commons.getWxConfig({pathUrl: encodeURI( url.indexOf('#' > -1) ? url.split('#')[0] : url)}).then(res => {
      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: res.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: [
          'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'
        ],
        openTagList: [
          'wx-open-subscribe',
          'wx-open-subscribe-dialog'
        ]
      });
      wx.ready(() => {
        this.wxa = true
      })
    })
    // window.addEventListener("beforeunload", () => {
    //   console.log('beforeunload',this.$route, this.$route.fullPath)
    //   let from = this.$route;
    //   if(store.state.pageLog[from.fullPath]){
    //     const nowTime = new Date().getTime()
    //     let data = store.state.pageLog[from.fullPath]
    //     data['time'] = Math.ceil( (nowTime - data.startTime) / 1000)
    //     store.commit('clearPageLog', from.fullPath)
    //     Vue.prototype.$u.post(ajax.actCNZZ.api, data)
    //   }
    // });
  },
  methods: {
    getUrlCode(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1]
          .replace(/\+/g, '%20')) || null
    },
    checkWeChatCode() {
      let code = this.getUrlCode('code')
      if (code) {
        this.$u.api.users.getUserInfoByCode({code: code}).then(res => {
          console.log('res,', res)
          let tmpUser = Object.assign(this.user)
          tmpUser.openid = res.data.original.openid
          // store.commit('setUser', tmpUser)
          localStorage.setItem('openid', res.data.original.openid)
        })
      }else{
        if(!localStorage.getItem('openid')){
          var ua = window.navigator.userAgent.toLowerCase();
          //通过正则表达式匹配ua中是否含有MicroMessenger字符串
          if (ua.match(/MicroMessenger/i) == 'micromessenger' ) {
            window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx099750fd01343178&redirect_uri='+encodeURI(window.location.href)+'&response_type=code&scope=snsapi_base#wechat_redirect'
          }
        }
      }
    },
    hidePlayer(){
      this.playerShow = false
    },
    init (){
      /**
       * 根据ua判断来访者平台是pc或者手机
       */
      var ww=document.body.clientWidth;
      var wh=document.body.clientHeight;
      this.bili = ww/wh
      if(localStorage.getItem('user')){
        this.$store.commit('setUser', JSON.parse(localStorage.getItem('user')))
      }
      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        this.$store.commit('setBrowser', 2)
        if(this.bili > 0.6) {
          document.getElementById('viewport').content = 'target-densitydpi=device-dpi, width=750,maximum-scale=1.1,initial-scale=1.1,user-scalable=no'
        }else{
          document.getElementById('viewport').content = 'target-densitydpi=device-dpi, width=750,maximum-scale=1.0,user-scalable=no'
        }
        // document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, viewport-fit=cover,user-scalable=no'
      }else{
        if(document.body.clientWidth <= 1024){
          this.$store.commit('setBrowser', 2)
          this.classname = 'pad'
          if(this.bili > 0.6){
            document.getElementById('viewport').content = 'target-densitydpi=device-dpi, width=750,maximum-scale=1.1,initial-scale=1.1,user-scalable=no'
          }else{
            document.getElementById('viewport').content = 'target-densitydpi=device-dpi, width=750,maximum-scale=1.0,user-scalable=no'
          }
        }else{
          this.$store.commit('setBrowser', 1)
          this.classname = 'pad'
          document.getElementById('viewport').content = 'target-densitydpi=device-dpi, maximum-scale=1.0,user-scalable=no'
        }
      }
      this.$store.commit('setWindowHeight', document.body.clientHeight)
      this.$store.commit('setWindowWidth', document.body.clientWidth)
      this.$u.api.commons.getSiteConfig().then(res => {
        this.$store.commit('setSiteConfig', res.data[0])
      })
      this.resize()
      window.addEventListener('resize', this.resize)
    },
    closeMengban(){
      this.$store.commit('setShare', false)
    },
    getUserInfo(){
      this.$u.api.users.getUserInfo().then(res => {
        if(res.status === 1){
          this.$store.commit('updateUser', res.data)
        }
      })
    },
    resize () {
      setTimeout(() => {
        if (navigator.userAgent.indexOf('Mobile') !== -1) {
          var width = window.innerWidth,
              height = window.innerHeight
          console.log('width', width, height)
          this.hengping = width >= height && height < 360;
        }
      }, 200)
    },
  }
}
</script>

<style scoped lang="scss">
.mengban{
  width: 100%;
  height: 100%;
  background: url("./assets/images/mobile/sharejt.png") no-repeat rgba(0,0,0,0.6);
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999999;
}
.hengping{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  background-size: contain;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
  .hengping-pic{
    width: 180px;
    height: 184px;
    background: url("./assets/images/mobile/hengping.png");
    background-size: 100% 100%;

    -webkit-transform-style:preserve-3d;
    -webkit-perspective:0px;

    -moz-transform-style:preserve-3d;
    -moz-perspective:0px;

    -webkit-animation:mydhua 5s ease infinite;
    -moz-animation:mydhua 5s ease infinite;
  }
}


@-webkit-keyframes mydhua{
  0%{-webkit-transform:rotateX(0deg) rotateY(0deg) rotateZ(0deg);-webkit-transform-origin: center center;}
  50%{-webkit-transform:rotateX(0deg) rotateY(0deg) rotateZ(90deg);-webkit-transform-origin: center center; }
  100%{-webkit-transform:rotateX(0deg) rotateY(0deg) rotateZ(0deg);-webkit-transform-origin: center center; }
}
@-moz-keyframes mydhua{
  0%{-moz-transform:rotateX(0deg) rotateY(0deg) rotateZ(0deg);-webkit-transform-origin: center center;}
  50%{-moz-transform:rotateX(0deg) rotateY(0deg) rotateZ(90deg); -webkit-transform-origin: center center;}
  100%{-moz-transform:rotateX(0deg) rotateY(0deg) rotateZ(0deg); -webkit-transform-origin: center center;}
}
</style>
