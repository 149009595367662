<template>
  <div
    class="footer-item-mobile"
    :class="(info.type==='middle' ? ' middle' : '') + (on ? ' on' : '')"
    @click="$emit('click')"
  >
    <div class="pic">
      <img :src="!on ? info.icon : info.icon_on" />
    </div>
    <div
      class="text"
    >
      {{ $t(info.langid) }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'FooterItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    on: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.footer-item-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;
  .pic{
    width: 48px;
    height: 48px;
  }
  .text{
    font-size: 20px;
    font-weight: 400;
    color: rgba(255,255,255,.4);
    line-height: 28px;
    margin-top: 4px;
    overflow: hidden;
  }
  &.middle{
    .pic{
      width: 70px;
      height: 70px;
    }
  }
  &.on{
    .text{
      color: #FF754D;
    }
  }
}
</style>
