<template>
  <div
    class="header-right-lang-btn"
    :class="lang"
    @click.stop="$emit('click')"
  >
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'HeaderRightLangBtn',
  props: {
    text: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user'])
  }
}
</script>

<style scoped lang="scss">
.header-right-lang-btn{
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: url("../../../../../assets/images/anhei/pc/button_en.png");
  background-size: 100% 100%;
  &:hover{
    background: url("../../../../../assets/images/anhei/pc/button_en_hover.png");
    background-size: 100% 100%;
  }
  &.en{
    background: url("../../../../../assets/images/anhei/pc/button_cn.png");
    background-size: 100% 100%;
    &:hover{
      background: url("../../../../../assets/images/anhei/pc/button_cn_hover.png");
      background-size: 100% 100%;
    }
  }
}
</style>
