const common = {
    getChildArchivesType: { api: '/api.php/Tool/getChildArchivesType', },
    getDictionaryConfig: { api: '/api.php/Tool/getDictionaryConfig', },
    getAboutDetail: { api: '/api.php/About/getDetail', },
    getNewAds: { api: '/api.php/Tool/getNewAds', },
    getLiveDate: { api: '/api.php/Tool/getLiveDate', },
    getRanking: { api: '/api.php/Tool/ranking', },
    getSiteConfig: { api: '/api.php/Tool/getSiteConfig', },
    getWxConfig: { api: '/api.php/Tool/getWxConfig', },
    getZhanAds: { api: '/api.php/tool/getZhanAds', },
}
export default common
