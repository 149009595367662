import axios from 'axios'
import qs from 'qs'
import Setting from "@/config/setting.config";
import store from '@/store'
import router from '@/router'

// 创建axios实例
const service = axios.create({
	baseURL: Setting.appDomain.api, // api的base_url
	timeout: 10000, // 请求超时时间10s,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
})
let noAuth = [
	'/api.php/Tool/getWxConfig',
	// '/api.php/Ajax/actCNZZClick',
	'/api.php/Tool/getSiteConfig',
	'/api.php/Tool/getDictionaryConfig'
]
// request拦截器
service.interceptors.request.use(config => {
	if (store.getters.token && noAuth.indexOf(config.url) == -1) {
		config.headers['tokens'] = store.getters.token
	}
	config.headers['lang'] = store.state.lang
	config.headers['agent'] = store.state.browser === 1 ? 'pc' : 'mobile'
	if (config.data !== undefined && config.data !== null) {
		config.data = removeSpaces(config.data)
	}
	config.data = qs.stringify(config.data)
	return config
}, error => {
	Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.status !== 1) {
			if(response.data.status === 5 && response.request.responseURL.indexOf('getUserInfo') === -1){
				store.commit('logout')
				localStorage.setItem('backUrl', router.currentRoute.path)
				localStorage.setItem('backQuery', JSON.stringify(router.currentRoute.query))
				router.replace('/user/login')
			}
			return response.data
		} else {
			return response.data
		}
	},
	error => {
		return Promise.reject(error)
	}
)

//清除空格
function removeSpaces(data) {
	if (data === null || data === undefined) {
		return data
	}
	if ((typeof data) === 'string') {
		return data.trim()
	} else if ((typeof data) === 'object') {
		for (const i in data) {
			data[i] = removeSpaces(data[i])
		}
		return data
	} else {
		return data
	}
}
export default service
