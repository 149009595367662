import Vue from 'vue'
import App from './App.vue'
import http from '@/api/http/libs/request'
import i18n from '@/common/i18n/i18n'
import store from '@/store'
import router from './router'
import '@/assets/style.scss';
import timeFormat from '@/common/timeFormat'
import timeFrom from '@/common/timeFrom'
import deepClone from '@/common/deepClone'
import '@/assets/mintui.css'
import Carousel3d from 'vue-carousel-3d';
import wx from 'weixin-js-sdk';
// import wx from 'jweixin-module';
import $ from 'jquery'
import 'animate.css';
import VueKinesis from 'vue-kinesis'

Vue.use(VueKinesis);
import 'babel-polyfill'
import 'classlist-polyfill'
Vue.use(Carousel3d);
import vueTouch from 'kim-vue-touch'
Vue.use(vueTouch)
import { Toast, Field } from 'mint-ui';
import {
  Select, Option, Button, Pagination, Empty, Form, FormItem, Input, Message, Steps, Step, Upload, Alert, MessageBox, Result, InfiniteScroll
} from "element-ui";

Vue.use(InfiniteScroll)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Button.name, Button)
Vue.component(Pagination.name, Pagination)
Vue.component(Empty.name, Empty)
Vue.component(Field.name, Field);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Input.name, Input);
Vue.component(Message.name, Message);
Vue.component(Alert.name, Alert);
Vue.component(Steps.name, Steps);
Vue.component(Step.name, Step);
Vue.component(Upload.name, Upload);
Vue.component(Result.name, Result);
Vue.prototype.$message = Message
Vue.prototype.$wx = wx
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm

Vue.prototype.toast = (msg) => {
  Toast( msg);
}
Vue.prototype.htmlToString = (str) => {
  return str.replace(/<\/?.+?\/?>/g,'');
}
let userAgent = navigator.userAgent;
let isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
if (isIOS) {
  store.commit("firstUrlFun", window.location.href.split("#")[0]);
}
Vue.prototype.isIOS = () => {
  return !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}
Vue.config.ignoredElements = ['wx-open-launch-weapp', 'wx-open-subscribe']
Vue.prototype.bankuai = () => {
  return '版块'
}
let kk = 0
Vue.prototype.getWxConfig = (title, desc, img) => {
  if(kk==1){
    // return
  }
  kk=1;
    // let url = isIOS ? store.state.firstUrl.split('#')[0] : window.location.href
    let url = window.location.href
    // alert(url, window.location.href)
    if(title === undefined || !title){
      if(store.state.lang === 'zh'){
          title = '数字工博'
      }else{
          title = 'CIIF'
      }
      document.title = title
    }else{
      if(store.state.lang === 'zh'){
        if(title !== '数字工博GoCIIF，等您来探索' && title !== '数字工博'){
          title = '数字工博 | '+title
        }
      }else{
        if(title !== '数字工博GoCIIF，等您来探索' && title !== 'CIIF') {
          title = 'CIIF | ' + title
        }
      }
      document.title = title
    }
    let imgUrl = 'https://virtualobs.ciif-expo.com/Uploads/images/2022-05-30/tp62941163b3b265.22872971.jpeg';
    if(img){
      imgUrl = img
    }
    $('#shareimg').remove()
    $('body').prepend('<div id="shareimg" style =\'margin:0 auto;width:0px;height:0px;overflow:hidden;  \' >\n' +
        '\n' +
        '<img src=\''+imgUrl+'\' />\n' +
        '\n' +
        '</div >')
    Vue.prototype.$u.api.commons.getWxConfig({pathUrl: encodeURI( url.indexOf('#' > -1) ? url.split('#')[0] : url)}).then(res => {
      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: res.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: [
          'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'
        ],
        openTagList: [
            'wx-open-subscribe',
            'wx-open-subscribe-dialog'
        ]
      });
      desc = replaceHtmlTag(desc)
      wx.ready(function() {
        url = url  + (window.location.href.indexOf('?') > -1 ? '&' : '?') + 'share=1'
        setShare(title, desc, url, imgUrl)
        setTimeout(() => {
          kk=0
        },5000)
      })
    })
}
function setShare(title, desc, link, imgUrl){
  title = htmlspecialchars_decode(replaceHtmlTag(title))
  desc = htmlspecialchars_decode(replaceHtmlTag(desc))
  wx.ready(function () {
    if (wx.updateTimelineShareData) {
      wx.updateTimelineShareData({
        title: title, // 分享标题
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
          console.log('updateTimelineShareData success')
        },
        fail: function (e) {
          console.log('updateTimelineShareData e'+ JSON.stringify(e))
          wx.onMenuShareTimeline({
            title: title, // 分享标题
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
              console.log('111')
              // 设置成功
            },
            fail: function(e){
              console.log('onMenuShareTimeline', e)
            }
          })
        }
      })
    } else {
      wx.onMenuShareTimeline({
        title: title, // 分享标题
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
        }
      })
    }
    if (wx.updateAppMessageShareData) {
      wx.updateAppMessageShareData({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
        },
        fail: function (e) {
          wx.onMenuShareAppMessage({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
              // 设置成功
            }
          })
        }
      })
    } else {
      wx.onMenuShareAppMessage({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {
          // 设置成功
        }
      })
    }
  })
}
Vue.prototype.padding4 = (num, length) =>{
  //这里用slice和substr均可
  return (Array(length).join("0") + num).slice(-length);
}
Vue.prototype.$u = {
  get: http.get,
  post: http.post,
  put: http.put,
  http,
  api: {}
}
// http接口API抽离，免于写url或者一些固定的参数
import httpApi from '@/api/http/http.api.js'
import '@/assets/theme/pc/theme/index.css'
import {Loading} from "element-ui";


import VueLazyLoad from 'vue-lazyload'
  Vue.use(VueLazyLoad, {
    preLoad: 1.3,
    error: require('./assets/images/newlogo/logo_zheng.png'),
    loading: require('./assets/images/lazyload.jpg'),
  })

Vue.use(httpApi, app)
var title = store.state.lang === 'zh' ? '数字工博' : 'CIIF'
var desc = store.state.lang === 'zh' ? '数字工博' : 'CIIF'
Vue.prototype.getWxConfig(title, desc)
/**
 * 公共跳转函数
 * @param path
 */
Vue.prototype.navigateTo = (path) => {
  if(path.toLowerCase().indexOf('ive?typeCurrent=1') > -1 && isIOS){
    if (navigator.userAgent.indexOf('Mobile') > -1) {
      window.location.href = path
      return
    }
  }
  if (navigator.userAgent.indexOf('Mobile') > -1) {
    router.replace({
      path: path
    })
  }else{
    router.push({
      path: path
    })
  }
  // window.location.replace(path)
};
Vue.prototype.replaceTo = (path) => {
  if(path.toLowerCase().indexOf('ive?typeCurrent=1') > -1 && isIOS){
    if (navigator.userAgent.indexOf('Mobile') > -1) {
      window.location.href = path
      return
    }
  }
  router.push({
    path: path
  })
  // window.location.replace(path)
};
Vue.prototype.replaceSearch = (lang, keyword, text, text_en) => {
  if(keyword !== '') {
    if (lang === 'zh') {
      return text ? text.replace(new RegExp(keyword, 'g'), '<span>' + keyword + '</span>') : ''
    } else {
      return text_en ? text_en.replace(new RegExp(keyword, 'g'), '<span>' + keyword + '</span>') : ''
    }
  }else{
    if (lang === 'zh') {
      return text || ''
    }else{
      return text_en || ''
    }
  }
};
var u = navigator.userAgent,
    app = navigator.appVersion;
Vue.prototype.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
Vue.prototype.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

Vue.prototype.timeFormat = timeFormat;
Vue.prototype.timeFrom = timeFrom;
Vue.prototype.scrollAuto = function(){
  this.$nextTick(() => {
    if(this.$route.meta.keepAlive){
      const scrollTop = this.$route.meta.scrollTop;
      const $content = document.querySelector('#app');
      if (scrollTop && $content) {
        $content.scrollTop = scrollTop;
      }else{
        $content.scrollTop = 0
      }
    }else{
      const $content = document.querySelector('#app');
      $content.scrollTop = 0
      const $content1 = document.querySelector('body');
      $content1.scrollTop = 0
    }
  })
}
Vue.prototype.deepClone = deepClone
Vue.prototype.goTop = function(){
  const $content = document.querySelector('#app');
  $content.scrollTop = 0
}
Vue.prototype.htmlspecialchars_decode = function(str){
  str = str.replace(/&amp;/g, '&');
  str = str.replace(/&lt;/g, '<');
  str = str.replace(/&gt;/g, '>');
  str = str.replace(/&quot;/g, "\"");
  str = str.replace(/&#039;/g, "'");
  return str;
}
function htmlspecialchars_decode(str){
  str = str.replace(/&amp;/g, '&');
  str = str.replace(/&lt;/g, '<');
  str = str.replace(/&gt;/g, '>');
  str = str.replace(/&quot;/g, "\"");
  str = str.replace(/&#039;/g, "'");
  str = str.replace(/&nbsp;/g, "");
  return str;
}
Vue.prototype.sortBykey = function (ary, key) {
  return ary.sort(function (a, b) {
    let x = a[key]
    let y = b[key]
    return ((x < y) ? -1 : (x > y) ? 1 : 0)
  })
}

Vue.prototype.loadingInstance = Loading.service({ fullscreen: true,  background: 'rgba(100,100,100,0.1)', lock: true, });
Vue.prototype.$t2 = (lang, cn, en) => {
  return lang === 'zh' ? cn : en
}
Vue.config.productionTip = false

Vue.prototype.getStorageSync = (key) => {
  return localStorage.getItem(key)
}

Vue.prototype.setStorageSync = (key, value) => {
  localStorage.setItem(key, value)
}
Vue.prototype.replaceHtmlTag = (str) => {
  var regex = /(<([^>]+)>)/ig
  return str.replace(regex, "");
}
function replaceHtmlTag(str){
  var regex = /(<([^>]+)>)/ig
  return str.replace(regex, "");
}

new Vue({
  i18n,
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线，$bus就是当前应用的vm
  },
  render: h => h(App),
}).$mount('#app')
