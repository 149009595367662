<template>
  <div
    :class="isshow ? '' : 'hidden'"
    class="subscribe-modal-mobile"
  >
    <div class="subscribe-modal-mobile-main-box">
      <div class="subscribe-modal-mobile-main-box-title">
        通知
      </div>
      <div class="subscribe-modal-mobile-main-box-tip">
        您已报名成功，是否订阅{{ typeName }}开始通知？
      </div>
      <div class="btn-view">
        <div
          v-html="subscribeNode"
        ></div>
      </div>
      <div class="close-btn"
           @click="closeModal">
        <btn-close-mobile
          size="80"
        />
      </div>
    </div>
  </div>
</template>

<script>

import BtnCloseMobile from "../BtnCloseMobile";
export default {
  name: 'SubscribeModalMobile',
  components: {BtnCloseMobile},
  data(){
    return {
      subscribeNode: '',
      isshow: false,
      btn: null,
      id: 0,
      typeName: ''
    }
  },
  mounted() {

  },
  beforeDestroy() {
    this.btn.addEventListener('success', null)
    this.btn.addEventListener('error', null)
  },
  methods: {
    closeModal(){
      this.isshow = false
      var mo=function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",mo,false);
    },
    show(id, typeName, template, type){
      this.typeName = typeName
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger' ) {
        this.id = id
        this.isshow = true
        var mo=function(e){e.preventDefault();};
        document.body.style.overflow='hidden';
        document.addEventListener("touchmove",mo,false);

        this.subscribeNode = "\n" +
            "    <wx-open-subscribe\n" +
            "      id=\"wechatSubscribe\"\n" +
            "      template=\""+ template +"\"\n" +
            "    >\n" +
            "   <script type=\"text\/wxtag-template\">\n" +
            "      <button style='font-size: 20px; border: 0; color: #fff; border-radius: 5px; outline: none; width: 200px; height: 60px; background: linear-gradient(223deg, #FC501E 0%, #FA8958 100%);'>立即订阅<\/button>\n" +
            "      <\/script>\n" +
            "    <\/wx-open-subscribe>";
        this.$nextTick(() => {
          this.btn = document.getElementById('wechatSubscribe');
          this.btn.addEventListener('success', (e) => {
            if(e.detail.errMsg == 'subscribe:ok'){
              this.$u.api.users.sub({subscribeDetails: e.detail.subscribeDetails, type: type, id: id, openid: localStorage.getItem('openid')})
              this.closeModal();
              this.toast('订阅成功');
            }
          });
          this.btn.addEventListener('error',function (e) {
            this.closeModal();
          });
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.subscribe-modal-mobile {
 position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 9999;
  &.hidden{
    left: -100%;
  }
  &-main-box{
    width: 80%;
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    &-title{
      line-height: 50px;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
    }
    &-tip{
      line-height: 50px;
      text-align: center;
    }
    .btn-view{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 20px;
    }
  }
  .close-btn{
    width: 68px;
    height: 68px;
    position: absolute;
    left: 50%;
    margin-left: -34px;
    bottom: -100px;
  }
}
</style>
