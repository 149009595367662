<template>
  <div class="fixed-menu-pc">
    <fixed-menu-item-pc
      icon="right_menu_1.png"
      text="10013"
      @click="navigateTo('/supplydemand')"
    />
    <div class="line">
      <split-line-pc
        bg-color="rgba(234, 76, 30, .1)"
        height="1px"
        margin-bottom="0"
        margin-top="0"
      />
    </div>
    <a href="http://virtual_vr.ciif-expo.com//tour/b7da61693c02c352" target="_blank">

    <fixed-menu-item-pc
      icon="right_menu_2.png"
      text="10012"
    />
    </a>
    <div
      class="line"
    >
      <split-line-pc
        bg-color="rgba(234, 76, 30, .1)"
        height="1px"
        margin-bottom="0"
        margin-top="0"
      />
    </div>
    <fixed-menu-item-pc
      icon="right_menu_3.png"
      text="10014"
      @click="navigateTo('/rank')"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import FixedMenuItemPc from "@/components/Pc/common/FixedMenu/components/item";
import SplitLinePc from "@/components/Pc/common/SplitLine";

export default {
  name: 'FixedMenuPc',
  components: {SplitLinePc, FixedMenuItemPc},
  props: {

  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.fixed-menu-pc{
  border: 1px solid #FFD8CC;
  width: 72px;
  transform: translateY(-50%);
  background: rgba(255,255,255,0.8);
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 5;
  .line{
    margin: 0 5px;
  }
}
</style>
