<template>
  <div
    class="split-line-pc"
    :style="{display: 'block', background: bgColor, height: height, width: '100%', marginTop: marginTop, marginBottom: marginBottom}"
  >
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'SplitLinePc',
  props: {
    bgColor: {
      type: String,
      default: () => '#fafafa'
    },
    height: {
      type: String,
      default: () => '2px'
    },
    marginTop: {
      type: String,
      default: () => '2px'
    },
    marginBottom: {
      type: String,
      default: () => '2px'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped ></style>
