<template>
  <div
    v-if="nowPath !== ''"
    class="header-pc"
  >
    <div class="gonggao">
      <div class="container">
        <div class="ico"></div>
        <div class="gonggao-line">
          <marquee-text
            :duration="30"
            :repeat="1"
          >
            <template
              v-for="i in 280"
            >
              <span
                :key="i"
              >&nbsp;</span>
            </template>
            {{
              $t2(lang, siteConfig.site_notice, siteConfig.site_notice_en)
            }}
          </marquee-text>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        class="logo"
        @click="navigateTo('/')"
      >
        <img src="../../../../assets/images/newlogo/logo.png" />
      </div>
      <div class="menu">
        <ul>
          <template
            v-for="(res, index) in menu"
          >
            <template v-if="lang !== 'en' || (lang === 'en' && !res.enHide)">
              <li
                v-if="res.path.indexOf('https://') > -1"
                :key="index"
                :class="menuPath[res.langid].split('/')[1].toLowerCase() === nowPath.toLowerCase() ? `on` : ``"
              >
                <a
                  :href="res.path"
                  @click="navigateTo(menuPath[res.langid])"
                >{{ $t(res.langid) }}</a>
              </li>
              <li
                v-else
                :key="index"
                :class="menuPath[res.langid].split('/')[1].toLowerCase() === nowPath.toLowerCase() ? `on` : ``"
              >
                <a
                  href="javascript:void(0)"
                  @click="navigateTo(menuPath[res.langid])"
                >{{ $t(res.langid) }}</a>
              </li>
            </template>
          </template>
        </ul>
      </div>
      <div class="header-right">
        <header-right-search-btn />
        <header-right-lang-btn
          @click="changeLang"
        />
        <header-right-btn
          v-if="!user.tokens"
          :auto-width="true"
          :text="$t('10011')"
          @click="navigateTo('/user/login')"
        />
        <user-logined-pc v-else />
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import HeaderRightBtn from "@/components/Pc/common/Header/components/headerRightBtn";
import HeaderRightSearchBtn from "@/components/Pc/common/Header/components/headerRightSearchBtn";
import loadLanguageAsync from "@/common/i18n/i18n";
import UserLoginedPc from "../UserLogined";
import MarqueeText from 'vue-marquee-text-component'
import HeaderRightLangBtn from "./components/headerRightLangBtn";
export default {
  name: 'HeaderPc',
  components: {HeaderRightLangBtn, UserLoginedPc, HeaderRightSearchBtn, HeaderRightBtn, MarqueeText},
  computed: {
    ...mapState(['browser', 'menu', 'menuPath', 'lang', 'user', 'siteConfig', 'hideEnMenu']),
    nowPath: function(){
      return this.$route.path.split('/')[1]
    },
    showLang(){
      if(this.hideEnMenu){
        if(this.hideEnMenu.indexOf('/'+this.nowPath) > -1){
          return false
        }else{
          return true
        }
      }
      return true
    }
  },
  methods:{
    changeLang() {
      loadLanguageAsync(this.lang === 'en' ? 'zh' : 'en')
    }
  }

}
</script>

<style scoped lang="scss">
.header-pc{
  position: relative;
  .logo{
    cursor: pointer;
  }
  .gonggao{
    position: absolute;
    bottom: -38px;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: rgba(255,255,255,0.1);
    font-size: 14px;
    font-weight: 600;
    color: #EA4C1E;
    backdrop-filter: blur(2px);
    .container{
      display: flex;
      justify-items: self-start;
      align-items: center;
      .ico{
        margin-left: 19px;
        width: 20px;
        height: 20px;
        background: url("../../../../assets/images/anhei/pc/icon_announcement.png");
        background-size: 100% 100%;
        display: inline-block;
      }
      .gonggao-line{
        width: 1120px;
        margin-left: 8px;
      }
    }
  }
}
</style>
