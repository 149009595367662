<template>
  <div>
    <div
        v-if="(nowPath !== '' && path !== '/user/login' && path !== '/Exhibition' && path !== '/supplydemand' && path !== '/Search' && path !== '/search') && showFooter"
        class="footer-pc clearfix"
    >
      <span>{{ siteConfig.companybei }}</span>
      <span>{{ $t2(lang, siteConfig.banquan, siteConfig.banquan_en) }}</span>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'FooterPc',
  data(){
    return {
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'siteConfig', 'noFooterPc', 'showFooter']),
    nowPath: function(){
      return this.$route.path.split('/')[1]
    },
    path: function(){
      return this.$route.path
    }
  },
  activated() {
    this.showFooter = false
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.footer-pc {
  text-align: center;
  color: #333333;
  font-weight: 400;
  background: #D1D0CD;
  height: 80px;
  line-height: 80px;
  margin-top: 40px;

  span {
    margin: 0 6px;
  }
}
</style>
