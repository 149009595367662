import Vue from 'vue'
import VueRouter from 'vue-router'
import loadLanguageAsync from '@/common/i18n/i18n.js'
import store from '@/store'
import ajax from '@/api/modules/ajax'
import search from '@/api/modules/search'
import user from "@/api/modules/user";
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        name: 'Slider',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Slider/index.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/index',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Index/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/index2',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Index/index2.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/forum/info',
        name: 'BbsInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Bbs/info.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/forum/jiabin',
        name: 'BbsJiabin',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Bbs/jiabin.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/forum/:year',
        name: 'Bbs',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Bbs/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/forum',
        name: 'Bbs',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Bbs/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/exhibition',
        name: 'Exhibition',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Exhibition/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/exhibition/list',
        name: 'ExhibitionList',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Exhibition/list.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/exhibition/info',
        name: 'ExhibitionInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Exhibition/info.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: true,
        }
    },
    {
        path: '/exhibition/productinfo',
        name: 'ExhibitionProductInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Exhibition/productinfo.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/live',
        name: 'LiveIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Live/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/awards',
        name: 'AwardsIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Awards/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/awards/list',
        name: 'AwardsList',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Awards/list.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/awards/info',
        name: 'AwardsInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Awards/info.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/rank',
        name: 'Rank',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Rank/index.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/supplydemand',
        name: 'SupplyDemand',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/SupplyDemand/index.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/news',
        name: 'NewsIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/News/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/news/info',
        name: 'NewsInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/News/info.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/activity',
        name: 'ActivityIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Activity/index.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/activity/info',
        name: 'ActivityInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Activity/info.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/case',
        name: 'CaseIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Case/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/case/info',
        name: 'CaseInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Case/info.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/topic',
        name: 'TopiocIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Topic/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/topic_cover',
        name: 'TopiocCover',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Topic/cover.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/about',
        name: 'AboutIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/About/index.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/search',
        name: 'SearchIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Search/index.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/search/list',
        name: 'SearchList',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Search/list.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user/login',
        name: 'UserLogin',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/login.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user',
        name: 'UserIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/index.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user/collect',
        name: 'UserCollect',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/collect.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user/schedule',
        name: 'UserSchedule',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/schedule.vue'),
        meta: {
            keepAlive: true,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user/supply',
        name: 'UserSupply',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/supply.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user/card',
        name: 'UserCard',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/card.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user/post_supply',
        name: 'PostSupply',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/postSupply.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/video',
        name: 'Video',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/Video/index.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
    {
        path: '/user/supply_product',
        name: 'supplyProduct',
        component: () => import(/* webpackChunkName: "about" */ '../views/Default/User/supplyProduct.vue'),
        meta: {
            keepAlive: false,
            scrollTop: 0,
            needRefresh: false,
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     }
    //     // 如果keepAlive的话，保存停留的位置：
    //     if (from.meta.keepAlive) {
    //         from.meta.scrollTop = document.getElementById('app').scrollTop;
    //     }
    //     // return 期望滚动到哪个的位置
    //     return {
    //         x: 0,
    //         y: to.meta.scrollTop || 0,
    //     };
    // }
})
const authListUrl = [
    '/user',
    '/user/post_suppy',
]
const zhUrlList = [
    '/activity',
    '/news',
    '/case',
    '/topic',
]

router.beforeEach((to, from, next) => {
    const menu = store.state.menu;
    let path = '/'+to.fullPath.split('/')[1];
    path = path.split('?')[0];
    store.commit('setShowFooter', false)
    to.meta.fromUrl = from.fullPath
    setTimeout(() => {
        store.commit('setShowFooter', true)
    },2000)

    menu.map(res => {
        if(res.path.toLowerCase() === path.toLowerCase()){
            const data = {
                path: to.fullPath,
                model: res.langid,
                fullpath: window.location.href
            }
            store.commit('setPageLog', data)
            Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
        }
    })
    if('/supplydemand' === path.toLowerCase()){
        const data = {
            path: to.fullPath,
            model: '10013',
            fullpath: window.location.href
        }
        store.commit('setPageLog', data)
    }
    // if('/user/login' === to.fullPath.toLowerCase()){
    //     const data = {
    //         path: to.fullPath,
    //         model: 10011
    //     }
    //     store.commit('setPageLog', data)
    // }
    if(to.name === 'Slider'){
        const data = {
            path: to.path,
            model: 10029,
            fullpath: window.location.href
        }
        store.commit('setPageLog', data)
        Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    }
    if(to.name === 'Home'){
        const data = {
            path: to.path,
            model: 10029,
            fullpath: window.location.href
        }
        store.commit('setPageLog', data)
        Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    }
    // if(to.fullPath.toLowerCase().indexOf('/index') > -1){
    //     const data = {
    //         path: to.fullPath,
    //         model: 10028,
    //         fullpath: window.location.href
    //     }
    //     store.commit('setPageLog', data)
    //     Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    // }
    // if('/user' === path.toLowerCase()){
    if(to.name === 'UserIndex'){
        const data = {
            path: to.path,
            model: 10021,
            fullpath: window.location.href
        }
        Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    }
    // if('/supplydemand' === path.toLowerCase()){
    if(to.name === 'SupplyDemand'){
        const data = {
            path: to.path,
            model: 10013,
            fullpath: window.location.href
        }
        Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    }
    // if('/rank' === to.fullPath.toLowerCase()){
    if(to.name === 'Rank'){
        const data = {
            path: to.path,
            model: 10014,
            fullpath: window.location.href
        }
        Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    }
    // if('/search' === path.toLowerCase()){
    if(to.name === 'SearchIndex'){
        const data = {
            path: to.path,
            model: 50025,
            fullpath: window.location.href
        }
        Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    }
    if(from.name === 'SearchList'){
        const typeName = to.name
        let type = '', id = 0
        switch(typeName){
            case 'ExhibitionInfo':
                //展商
                type = '展商'
                id = to.query.id
            break;
            case 'ExhibitionProductInfo':
                //展品
                type = '展品'
                id = to.query.id
            break;
            case 'BbsInfo':
                //论坛
                type = '论坛'
                id = to.query.id
            break;
            case 'AwardsInfo':
                //评奖
                type = '评奖'
                id = to.query.id
            break;
            case 'NewsInfo':
                //资讯
                type = '资讯'
                id = to.query.id
            break;
            case 'ActivityInfo':
                //活动
                type = '活动'
                id = to.query.id
            break;
            case 'CaseInfo':
                //案例
                type = '案例'
                id = to.query.id
            break;
            case 'TopiocIndex':
                //专题
                type = '专题'
                id = to.query.typeid
            break;
        }
        let params = {
            id: id,
            type: from.query.type,
            keywords: from.query.keyword,
            fullpath: window.location.href
        }
        if(id){
            Vue.prototype.$u.post(search.getSearchResultInfo.api, params)
        }
    }
    if(zhUrlList.indexOf(path.toLowerCase()) > -1){
        loadLanguageAsync('zh')
    }
    if(store.state.pageLog[from.fullPath] && from.fullPath !== to.fullPath){
        const nowTime = new Date().getTime()
        let data = store.state.pageLog[from.fullPath]
        data['time'] = Math.ceil( (nowTime - data.startTime) / 1000)
        store.commit('clearPageLog', from.fullPath)
        if(data['time'] > 10 && from.fullPath == '/'){
            data['time'] = 6
        }
        Vue.prototype.$u.post(ajax.actCNZZ.api, data)
    }

    if(authListUrl.indexOf(to.fullPath) > -1){
        setTimeout(function(){
            if(!store.getters.token){
               next( false)
                router.replace('/user/login')
            }
        },100)
    }
    const lang = Vue.prototype.getStorageSync('lang') || 'zh'
    if (from.meta.keepAlive) {
        from.meta.needRefresh = false;
        const $content = document.querySelector('#app');
        from.meta.scrollTop = $content.scrollTop;
    }
    //判断如果是点击了后退按钮 那么清理掉滚动条 并且增加判断需要刷新
    if (to.meta.keepAlive){
        if(!store.state.isPageBack){
            // to.meta.scrollTop = 0;
            to.meta.needRefresh = true;
        }
        store.commit('setIsPageBack', false);
    }
    loadLanguageAsync(lang).then(res => {
        if(res.audit_status === '1'){
            next({
                path: '/pages/my/shenhe?en=shenhe&back=0',
                NAVTYPE: 'push'
            })
        }else{
            if(!localStorage.getItem('checkToken') && store.getters.token){
                Vue.prototype.$u.post(user.actCheckToken.api, {}).then(res => {
                    console.log('res', res)
                    localStorage.setItem('checkToken', true)
                    if(res.status === 6){
                        console.log('2222')
                        store.commit('setUser', {})
                        next()
                    }else{
                        next()
                    }
                })
            }else{
                next()
            }
        }
    })
    return false
})

router.afterEach((to, from) => {
    const menu = store.state.menu;
    const path = to.path;
    // 若为ios系统则进行重定向获取最新的url便于微信获取权限签名
    // menu.map(res => {
    //     if(res.path.toLowerCase() === path.toLowerCase()){
    //         const data = {
    //             path: path,
    //             model: res.langid,
    //             fullpath: window.location.href
    //         }
    //         store.commit('setPageLog', data)
    //         Vue.prototype.$u.post(ajax.actCNZZClick.api, data)
    //     }
    // })
})

export default router
