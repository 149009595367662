import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        browser: null,
        lang: 'zh',
        share: false,
        menuPath: {},
        showFooter: false,
        bodyclassname: '',
        siteConfig: {
            search_keyword_cn: ''
        },
        searchHistory: (localStorage.getItem('searchHistory') && JSON.parse(localStorage.getItem('searchHistory'))) || [],
        firstUrl: '',
        user: {},
        // user: {"mid":708399,"name":"李鑫","qrcode":"","mobile":"13284661466","tokens":"9a35d368bf418b94744597c25fd2980d","logintime":1650868859,"addtime":1650868859},
        noFooter: [
            '/',
            '/activity/info',
            '/forum/info',
            '/exhibition/info',
            '/user/supply_product',
            '/supplydemand',
            '/user/login',
            '/user/post_supply',
            '/index',
            '/video'
        ],
        noFooterPc: [
            '/user/login',
            '/Search',
        ],
        langLoaded: false,
        isPageBack: false, //用于判断是否是点击返回按钮
        windowHeight: 1000,
        windowWidth: 750,
        userMenu: [
            {
                // title: '个人信息',
                title: '30001',
                link: '/user',
            },
            {
                // title: '我的收藏',
                title: '30002',
                link: '/user/collect',
            },
            {
                // title: '我的名片',
                title: '10020',
                link: '/user/card',
            },
            {
                // title: '我的日程',
                title: '30003',
                link: '/user/schedule',
            },
            {
                // title: '我的供需',
                title: '30004',
                link: '/user/supply',
            },
        ],
        pageLog: {

        },
        menu: [
            {
                title: '展览',
                langid: 10002,
                path: '/Exhibition'
            },
            {
                title: '论坛',
                langid: 10003,
                path: '/Forum'
            },
            {
                title: '直播间',
                langid: 10004,
                path: '/Live'
            },
            {
                title: '评奖',
                langid: 10005,
                path: '/Awards'
            },
            {
                title: '资讯',
                langid: 10006,
                path: '/news',
                enHide: true
            },
            {
                title: '活动',
                langid: 10007,
                path: '/activity',
                enHide: true
            },
            {
                title: '案例',
                langid: 10008,
                path: '/case',
                enHide: true
            },
            {
                title: '专题',
                langid: 10009,
                path: '/topic',
                enHide: true
            },
            {
                title: '关于我们',
                langid: 10010,
                path: '/about'
            },
        ],
        searchType:  [
            {
                label: '10002',
                value: '展览',
            },
            {
                label: '10026',
                value: '产品',
            },
            {
                label: '10003',
                value: '论坛',
            },
            {
                label: '25006',
                value: '视频',
            },
            {
                label: '10005',
                value: '评奖',
            },
            {
                label: '10006',
                value: '资讯',
            },
            {
                label: '10007',
                value: '活动',
            },
            {
                label: '10008',
                value: '案例',
            },
            {
                label: '10009',
                value: '专题',
            }
        ],
        hideEnMenu: [
            '/news',
            '/activity',
            '/case',
            '/topic',
            '/topic_cover',
        ]
    },
    getters: {
        token: state => state.user.tokens
    },
    mutations: {
        ['firstUrlFun'](state, data) {
            state.firstUrl = data;
        },
        ['setBodyClass'](state, data) {
            state.bodyclassname = data;
        },
        ['setShowFooter'](state, data) {
            state.showFooter = data;
        },
        ['setBrowser'](state, data) {
            state.browser = data;
        },
        ['setWindowHeight'](state, data) {
            state.windowHeight = data;
        },
        ['setWindowWidth'](state, data) {
            state.windowWidth = data;
        },
        ['setLang'](state, data) {
            localStorage.setItem('lang', data)
            state.lang = data;
        },
        ['setLangLoaded'](state) {
            state.langLoaded = true;
        },
        ['setMenuPath'](state, data) {
            state.menuPath = data;
        },
        ['setIsPageBack'](state, data) {
            state.isPageBack = data;
        },
        ['setShare'](state, data) {

            state.share = data;
        },
        ['setUser'](state, data) {
            localStorage.setItem('user', JSON.stringify(data))
            state.user = data;
        },
        ['updateUser'](state, data) {
            let user = state.user
            user.name = data.name
            user.isOnlineFair = data.isOnlineFair
            user.mobile = data.mobile
            user.companyname = data.companyname
            user.qrcode = data.qrcode
            localStorage.setItem('user', JSON.stringify(user))
            state.user = user;
        },
        ['logout'](state) {
            localStorage.removeItem('user')
            state.user = {};
        },
        ['setSiteConfig'](state, data) {
            state.siteConfig = data;
        },
        ['setPageLog'](state, data) {
            data.startTime = new Date().getTime()
            let a = JSON.parse(JSON.stringify(state.pageLog))
            a[data.path] = data
            state.pageLog = a;
        },
        ['clearPageLog'](state, path) {
            state.pageLog[path] = null;
        },
        ['setHistory'](state, data) {
            let old = JSON.parse(JSON.stringify(state.searchHistory))
            old.map((res, key) => {
                if(JSON.stringify(res) === JSON.stringify(data)){
                    old.splice(key, 1)
                }
            })
            old.unshift(data)
            old = old.slice(0, 10)
            localStorage.setItem('searchHistory', JSON.stringify(old))
            state.searchHistory = old;
        },
    }
})
