const user = {
    sendSms: { api: '/api.php/Login/send', },
    actCheck: { api: '/api.php/Login/actCheck', },
    actCheckToken: { api: '/api.php/Login/actCheckToken', },
    getMyCang: { api: '/api.php/Member/getMyCang', },
    getMySchedule: { api: '/api.php/Member/getMySchedule', },
    getSupplyLists: { api: '/api.php/Member/getSupplyLists', },
    getMyMingpian: { api: '/api.php/Member/getMyMingpian', },
    getMyFollow: { api: '/api.php/Member/getMyFollow', },
    actSaveSupply: { api: '/api.php/Member/actSaveSupply', },
    actSupplyDelete: { api: '/api.php/Member/actSupplyDelete', },
    getSupplyOne: { api: '/api.php/Member/getSupplyOne', },
    dropMySchedule: { api: '/api.php/Member/dropMySchedule', },
    getSupplyProductLists: { api: '/api.php/Member/getSupplyProductLists', },
    getUserInfo: { api: '/api.php/Member/getUserInfo', },
    checkBind: { api: '/api.php/Wechat/checkBind', },
    getUserInfoByCode: { api: '/api.php/Wechat/getUserInfoByCode', },
    sub: { api: '/api.php/Wechat/sub', },
    checkSub: { api: '/api.php/Wechat/checkSub', },
}
export default user
