<template>
  <div>
    <header-pc v-if="browser===1" />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import HeaderPc from "@/components/Pc/common/Header";
export default {
  name: 'Header',
  components: {HeaderPc},
  computed:  {
    ...mapState(['browser', 'menu'])
  }
}
</script>

<style scoped lang="scss"></style>
