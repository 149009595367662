const ajax = {
    actZan: { api: '/api.php/Ajax/actZan', },
    actShare: { api: '/api.php/Ajax/actShare', },
    actCang: { api: '/api.php/Ajax/actCang', },
    actCard: { api: '/api.php/Ajax/actCard', },
    actCNZZ: { api: '/api.php/Ajax/actCNZZ', },
    actCNZZClick: { api: '/api.php/Ajax/actCNZZClick', },
    actCNZZAds: { api: '/api.php/Ajax/actCNZZAds', },
    actComment: { api: '/api.php/Ajax/actComment', },
    actLive: { api: '/api.php/Ajax/actLive', },
}
export default ajax
