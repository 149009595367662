<template>
  <div
    class="header-right-search-btn"
    @click="navigateTo('/search')"
  >
  </div>
</template>

<script>

export default {
  name: 'HeaderRightSearchBtn',
  props: {
    text: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style scoped lang="scss">
.header-right-search-btn{
  margin-right: 12px;
  width: 32px;
  height: 32px;
  background: url("../../../../../assets/images/anhei/pc/top_search.png");
  background-size: 100% 100%;
  &:hover{
    background: url("../../../../../assets/images/anhei/pc/top_search_hover.png");
    background-size: 100% 100%;
  }
}
</style>
